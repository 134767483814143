.form {
    display: flex;
    flex-direction: column;
}

.button-group {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
    gap: 5;
}

.container {
    position: relative;
    min-height: 100vh;

}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../assets/images/home/background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
    /* opacity: 0.9; */
}

.logo-image {
    max-height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 20px;
}

.logo-image-container {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-self: center;
}